import React, { useEffect, useState } from 'react';
import frontMatter from 'front-matter';
import { marked } from 'marked';
import { Link } from 'react-router-dom'; // Assuming you are using React Router for routing
import '../BlogPage.css';

const BlogPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const files = [
        'first-update.md',
        'enter-the-final-void.md',
        'final-void-demo.md',
        // List all your markdown files here
      ];

      const fetchedPosts = await Promise.all(
        files.map(async (file) => {
          const response = await fetch(`/Updates/${file}`);
          if (!response.ok) {
            console.error(`Error fetching ${file}: ${response.statusText}`);
            return null;
          }
          const text = await response.text();
          const { attributes, body } = frontMatter(text);

          console.log(`File: ${file}`, attributes);
          console.log(`Parsed date for ${file}:`, new Date(attributes.date));
  

          const content = marked(body);
          const preview = content.substring(0, 200) + '...'; // Adjust length as needed
          return { id: file.replace(/\.md$/, ''), ...attributes, preview, content };
        
        })
      );

      setPosts(fetchedPosts.filter(post => post).sort((a, b) => new Date(b.date) - new Date(a.date)));
    };

    fetchPosts();
  }, []);

  return (
    <div className="BlogPage">
      {posts.map(({ id, title, date, author, preview }) => (
        <div key={id} className="BlogEntry">
          <h2 className="BlogTitle">{title}</h2>
          <div className="BlogContent" dangerouslySetInnerHTML={{ __html: preview }} />
          <Link to={`/blog-updates/${title.replace(/\s+/g, '-').toLowerCase()}`} className="ReadMore">
          Read More
        </Link> {/* Link to the full post using slugified title */}          <p className='AuthorName'>{author}</p>
          <p className="BlogDate">{new Date(date).toLocaleDateString()}</p>
        </div>
      ))}
    </div>
  );
};

export default BlogPage;
