import React from 'react'
import '../App.css'

const BuyMeCoffee = () => {
  return (
    <a href="https://www.buymeacoffee.com/dissonantdruid">
    <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=dissonantdruid&button_colour=40DCA5&font_colour=ffffff&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00" alt='Donation Link Button'/></a>
  )
}

export default BuyMeCoffee