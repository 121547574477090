import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../ContactStyles.css'; // Ensure to create and import the specific styles for the ContactPage

const ContactPage = () => {
  const form = useRef();
  const [status, setStatus] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm('service_94pu96k', 'template_tudruca', form.current, {
      publicKey: 'ZkYi06swVeY31syV9',
    })
      .then(
        () => {
          console.log('SUCCESS!');
          setStatus('SUCCESS');
        },
        (error) => {
          console.log('FAILED...', error.text);
          setStatus('FAILED');
        },
      );

    e.target.reset();
  };

  return (
    <div className='ContactSection'>
      <h1 className='ContactTitle'>Contact Us</h1>
      <p className='ContactDescription'>
        We would love to hear from you! Whether you have questions, feedback, or just want to say hello, <br />
        feel free to get in touch with us through the form below or by using the contact information provided. <br /><br />
        Your feedback and inquiries are important to us as we strive to create the best gaming experiences for you.
      </p>
      <form ref={form} onSubmit={sendEmail} className='ContactForm' id="contactForm">
        <label className='FormLabel'>Name</label>
        <input type="text" name="user_name" className='FormInput' required />
        <label className='FormLabel'>Email</label>
        <input type="email" name="user_email" className='FormInput' required />
        <label className='FormLabel'>Message</label>
        <textarea name="message" className='FormTextarea' required />
        <input type="submit" value="Send" className='FormButton' />
        {status === 'SUCCESS' && <p className='SuccessMessage'>Message sent successfully!</p>}
        {status === 'FAILED' && <p className='ErrorMessage'>Failed to send the message. Please try again.</p>}
      </form>
      <div className='ContactInfo'>
        <h2 className='InfoTitle'>Our Contact Information</h2>
        <p className='InfoDescription'>
          Email: dissonantdruid@gmail.com <br />

        </p>
      </div>
    </div>
  );
};


export default ContactPage;