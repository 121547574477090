import React from 'react'
import logo from '../static/dev_logo.png';
import gameScreenshot1 from '../static/neon_horizon_screenshot.jpg'
import gameScreenshot2 from '../static/neon_horizon_screenshot_2.jpg'
import gameScreenshot3 from '../static/neon_horizon_screenshot_3.jpg'
import TesterSignUpModal from './BecomeTester';

import "../App.css"

const HomePage = () => {



  return (
    <div className='logoContainer'>
        <img src={logo} className='App-logo' alt='Dev-Logo'/>
      <div className='AboutSection'>
        <h1 className='AboutUs'>About Us</h1>
        <p>Welcome to Dissonant Druid, where we aim to create immersive gaming experiences <br />
            with a keen focus on what truly matters to gamers. We believe in seeing <br />
            the world from a gamer's perspective, ensuring that every detail is <br />
            tailored to enhance your gameplay experience. Our first game has set the <br />
            stage, and we're excited to share that several more are in development, <br />
            each designed to push the boundaries of fun and engagement. Stay tuned <br />
            as we continue to bring our innovative ideas to life. Explore more about <br />
            our journey on the About Us page.</p>
      </div>
      <div className='FeaturedSection'>
        <h1 className='FeaturedTitle'>Featured Game</h1>
        <p className='FeaturedDescription'>Dive into the neon-lit world of our latest synthwave-themed <br />
                                           endless runner. Dodge obstacles at breakneck speeds, all <br />
                                           while soaking in the vibrant, retro-futuristic visuals <br />
                                           and pulsating synthwave soundtrack. Are you ready to test <br />
                                           your reflexes in this fast-paced action game?</p>
        <div className='Screenshots'>
          <img src={gameScreenshot1} alt='Screenshot 1' className='Screenshot' />
          <img src={gameScreenshot2} alt='Screenshot 2' className='Screenshot' />
          <img src={gameScreenshot3} alt='Screenshot 3' className='Screenshot' />
        </div>
        <a href="https://play.google.com/store/apps/details?id=com.neonhorizon.release&hl=en_US" target="_blank" rel="noopener noreferrer" className='PlayStoreLink'>
          <button className='PlayStoreButton'>Get it on Google Play</button>
        </a>
      </div>
      <TesterSignUpModal />
    </div>
  )
}

export default HomePage