import React from 'react';
import '../GameStyles.css';
import gameScreenshot1 from '../static/neon_horizon_screenshot.jpg';
import gameScreenshot2 from '../static/neon_horizon_screenshot_2.jpg';
import gameScreenshot3 from '../static/neon_horizon_screenshot_3.jpg';

const games = [
  {
    name: 'NEON HORIZON',
    description: 'Dive into the neon-lit world of our latest synthwave-themed endless runner. Dodge obstacles at breakneck speeds, all while soaking in the vibrant, retro-futuristic visuals and pulsating synthwave soundtrack. Are you ready to test your reflexes in this fast-paced action game?',
    url: 'https://play.google.com/store/apps/details?id=com.neonhorizon.release&hl=en_US',
    screenshots: [
      gameScreenshot1,
      gameScreenshot2,
      gameScreenshot3
    ]
  },
  // Add more games as needed
];

const GamesPage = () => {
  return (
    <div className='GamesPage'>
      <h1 className='PageTitle'>OUR GAMES</h1> 
      
      {games.map((game, index) => (
        <div key={index} className='GameSection'>
          <h1 className='GameTitle'>{game.name}</h1>
          <p className='GameDescription'>{game.description}</p>
          <div className='Screenshots'>
            {game.screenshots.map((screenshot, index) => (
              <img key={index} src={screenshot} alt={`Screenshot ${index + 1}`} className='Screenshot' />
            ))}
          </div>
          <a href={game.url} target="_blank" rel="noopener noreferrer" className='PlayStoreLink'>
            <button className='PlayStoreButton'>Get it on Google Play</button>
          </a>
        </div>
      ))}
    </div>
  );
};

export default GamesPage;
