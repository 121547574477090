import React from 'react';
import '../AboutStyles.css'; // Ensure to create and import the specific styles for the AboutPage
import BuyMeCoffee from './BuyMeCoffee';


const AboutPage = () => {
  return (
    <div className='AboutSection'>
      <h1 className='AboutTitle'>About Us</h1>
      <p className='AboutDescription'>
        Welcome to Dissonant Druid, where our mission is to create immersive and engaging gaming experiences. <br />
        We believe in designing games that truly resonate with players by focusing on what matters most to them. <br />
        We play games too, so we always aim to see the process from a gamer's perspective.<br /><br />

        Our first release, "Neon Horizon," was an exciting project that combined fast-paced action with a  <br />
        vibrant synthwave aesthetic, delivering a thrilling experience that was both challenging and visually captivating. <br /><br />

        At Dissonant Druid, we are committed to innovation and constantly pushing the boundaries of what games can be. <br />
        With several new projects in development, we are excited about the future and look forward to sharing more <br />
        groundbreaking games with our community. <br /><br />

        Thank you for being a part of our journey. We hope our games bring you as much joy and excitement as we had in creating them. <br />
        Stay tuned for more updates and new releases! <br /><br />

        If you love what we do, you can help us continue doing it by making even a small contribution!
      </p>
      <BuyMeCoffee />
    </div>
  );
}

export default AboutPage;
