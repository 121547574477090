import React from 'react'
import '../App.css'

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-text">
        © 2024 Dissonant Druid. All rights reserved.
      </p>
    </footer>
  )
}

export default Footer